import Vue from 'vue';
import { init } from 'emailjs-com';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins';

init('user_z7kvGKbs3jwf8vHqePHWp');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
