<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      height="75"
    >
      <v-img
        contain
        max-height="70%"
        src="./assets/metaverse_logic_logo.png"
      ></v-img>
    </v-app-bar>

    <v-main>
       <section
        id="hero"
        >
        <v-row
          no-gutters
          class="overflow-hidden"
        >
          <v-img
            src="./assets/main.jpg"
            :class="classConf"
            :height="$vuetify.breakpoint.smAndDown ? '800px' : '900px'"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                   <v-col
                    class="accent--text text-center"
                    cols="12"
                    tag="h1"
                  >
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light white--text"
                    >
                      WELCOME TO
                    </span>

                    <br>

                    <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-4']"
                      class="font-weight-black accent--text"
                    >
                      METAVERSE
                    </span>

                    <br>

                    <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-4']"
                      class="font-weight-black primary--text"
                    >
                      LOGIC
                    </span>

                  </v-col>

                  <!-- <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#about-me')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn> -->
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>
      <section
        id="features"
        class="grey lighten-3"
      >
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">Technology & Development</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="({ icon, title, text }, i) in features"
              :key="i"
              cols="12"
              md="4"
            >
              <v-card
                class="py-12 px-4"
                color="grey lighten-5"
                flat
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                      color="primary"
                      size="88"
                    >
                      <v-icon
                        large
                        v-text="icon"
                      ></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                  class="justify-center font-weight-black text-uppercase"
                  v-text="title"
                ></v-card-title>

                <v-card-text
                  class="subtitle-1"
                  v-text="text"
                >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="stats">
        <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 500 : 500"
          src="./assets/main_4.jpg"
        >
          <v-container fill-height>
            <v-row class="mx-auto">
              <v-col
                v-for="value in stats"
                :key="value"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                    v-text="value"
                  ></div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-parallax>
      </section>

      <section id="price">
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Corporate Website Packages</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              cols="12"
              md="4"
            >
            <v-card
            color="#214989"
            dark
          >
            <v-card-title class="text-h4 pt-10 mb-0 align-center justify-center font-weight-bold">
            Website</v-card-title>
            <v-card-title class=" text-h2 pb-10 pt-0 align-center justify-center font-weight-bold">
              {{policies[0].title}}
            </v-card-title>

          </v-card>
          <br>
          <ul
            v-for="(text , i) in policies[0].list"
            :key="i">
            <li class="text-h6">{{text}}</li>
          </ul>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
            <v-card
            color="#0089eb"
            dark
          >
          <v-card-title class="text-h4 pt-10 mb-0 align-center justify-center font-weight-bold">
            Website</v-card-title>
            <v-card-title class="text-h2 pb-10 pt-0 align-center justify-center font-weight-bold">
              {{policies[1].title}}
            </v-card-title>
          </v-card>
          <br>
          <ul
            v-for="(text , i) in policies[1].list"
            :key="i">
            <li class="text-h6">{{text}}</li>
          </ul>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
            <v-card
            color="#eb8f34"
            dark
          >
          <v-card-title class="text-h4 pt-10 mb-0 align-center justify-center font-weight-bold">
            Website</v-card-title>
            <v-card-title class="text-h2 pb-10 pt-0 align-center justify-center font-weight-bold">
              {{policies[2].title}}
            </v-card-title>
          </v-card>
          <br>
          <ul
            v-for="(text , i) in policies[2].list"
            :key="i">
            <li class="text-h6">{{text}}</li>
          </ul>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <v-sheet
        id="contact"
        color="#204888"
        dark
        tag="section"
        tile
      >
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contact Us</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="name"
                  :counter="10"
                  :rules="nameRules"
                  required
                  flat
                  label="Name*"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  required
                  flat
                  label="Email*"
                  solo
                ></v-text-field>
                <v-textarea
                  v-model="message"
                  :rules="messageRules"
                  required
                  flat
                  label="Message*"
                  solo
                ></v-textarea>

                <v-btn
                  v-if="!loading"
                  :disabled="!valid"
                  color="accent"
                  @click="sendEmail"
                  x-large
                >
                  Submit
                </v-btn>
                <v-progress-circular
                  v-if="loading"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-form>
          </v-theme-provider>
        </v-container>

        <div class="py-12"></div>
      </v-sheet>
    </v-main>

    <v-footer
      class="justify-center"
      color="#008aea"
      height="100"
    >
      <div class="title font-weight-light white--text text--lighten-1 text-center">
        &copy; 2021 — Metaverse Logic Sdn. Bhd.
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import emailjs from 'emailjs-com';
import axios from 'axios';

export default {
  name: 'App',
  metaInfo: {
    title: 'Metaverse Logic SDN BHD Homepage',
    titleTemplate: 'Metaverse Logic SDN BHD Homepage',
    htmlAttrs: { lang: 'en' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'keyword', content: 'Web development, Malaysia, Making homepage, System integrity, IT company' },
      { name: 'description', content: 'IT company for SI, homepage making' },
    ],
  },
  methods: {
    sendEmail2() {
      this.valid = false;

      let param = {};

      param = {
        name: this.name,
        email: this.email,
        message: this.message,
      };

      axios.post('https://sub.metaverselogic.io/email/metaverselogic',
        param).then((data) => {
        if (data.status === 200) {
          alert('Thank you. We will get back to you soon.');
          this.valid = true;
        } else {
          alert('Something is wrong. Please try it again.');
          this.valid = true;
        }
      }).catch((error) => {
        console.log(error);
        this.valid = true;
      });
    },
    sendEmail() {
      const templateParams = {
        name: this.name,
        email: this.email,
        message: this.message,
      };
      this.loading = true;
      emailjs.send('service_lk699hs', 'template_q40p3yc', templateParams, 'uoTW7OQd7BzNXqo4G')
        .then((response) => {
          console.log(response);
          alert('Thank you. We will get back to you soon.');
          this.loading = false;
        }, (error) => {
          console.log(error);
          alert('Error occur. Please try it for awhile later.');
          this.loading = false;
        });
    },
  },
  data() {
    return {
      classConf: '',
      loading: false,
      valid: true,
      name: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      message: '',
      messageRules: [
        (v) => !!v || 'Message is required',
      ],
      policies: [
        {
          title: 'EXPRESS',
          list: ['Professionally designed template (Single Web pages)', 'Logo integration', 'Google site maps', 'Photo gallery module', 'Enquiry form module'],
        },
        {
          title: 'PLUS',
          list: ['Professionally designed template (< 4 Web pages)', '4 menu items', 'Logo integration', 'Google site maps', 'Photo gallery module', 'Enquiry form module'],
        },
        {
          title: 'PREMIUM',
          list: ['Bilingual/ Multilingual site', 'Professionally designed template (< 6 Web pages)', '< 6 menu items', 'Logo integration', 'Google site maps', 'Photo gallery module', 'Enquiry form module'],
        },
      ],
      features: [
        {
          icon: 'mdi-web',
          title: 'Web Development',
          text: 'Robust business software solutions. Tailored to the growing needs of industries. Simplified operations and user-friendly software creations.         Interactive web pages. State of the art Front-end and Back-end Development.',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Mobile App Development',
          text: 'Outstanding mobile apps. Interactive user interface applications. Customizable and high quality services. Easy implementation.',
        },
        {
          icon: 'mdi-lock',
          title: 'Security ',
          text: 'Data security and cyber protection across all applications and platforms protecting digital data. Reduce risk of data exposure and data breaches. Confidentiality, integrity and data availability guaranteed.',
        },

      ],
      stats: [
        'IMAGINE',
        'DESIGN',
        'CREATIVE',
        'THRIVE',
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.classConf = 'enlarge';
    }, 1500);
  },
};
</script>

<style lang="sass">
  .enlarge
    transform: scale(1.5)
    transition: transform 10s
</style>
